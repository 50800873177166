import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyAuthPage = lazy(() => import('./AuthPage'));

const AuthPage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyAuthPage {...props} />
  </Suspense>
);

export default AuthPage;
