import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';

class LoadingContainer extends Component {
  constructor() {
    super();
    this.state = {
      displayLoader: false,
    };

    this.loaderDelay = null;
  }

  componentDidMount() {
    const { delayTimer } = this.props;

    this.loaderDelay = setTimeout(() => this.setState({ displayLoader: true }), delayTimer);
  }

  componentWillUnmount() {
    clearTimeout(this.loaderDelay);
  }

  render() {
    const { props } = this;
    const { displayLoader } = this.state;

    return <Loading {...props} displayLoader={displayLoader} />;
  }
}

LoadingContainer.propTypes = {
  delayTimer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LoadingContainer.defaultProps = {
  delayTimer: 1000,
};

export default LoadingContainer;
