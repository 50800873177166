// Requisitos técnicos e políticas de conteúdo
import React from 'react';

const Requirements = () => (
  <div className="container">
    <h2 className="toc-level-0 text-color--secondary">Requisitos técnicos e políticas de conteúdo</h2>
  </div>
);

export default Requirements;
