import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import '../../App.scss';

class SearchWidgetTemplate extends React.PureComponent {
  render() {
    const { children } = this.props;

    return <Fragment>{children}</Fragment>;
  }
}

SearchWidgetTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SearchWidgetTemplate;
