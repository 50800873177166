import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import '../../App.scss';

import Footer from '../../components/Footer/Footer';
import HeaderContainer from '../../components/Header/HeaderContainer';

class DefaultTemplate extends React.PureComponent {
  render() {
    const { children } = this.props;

    return (
      <Fragment>
        <Helmet
          defaultTitle="Britannica Escola"
          titleTemplate="%s | Britannica Escola"
          meta={[
            {
              name: 'description',
              content:
                'Britannica® Escola é uma plataforma de aprendizagem online para os alunos do Ensino Fundamental I.',
            },
            {
              name: 'keywords',
              content:
                'Artigos de la Enciclopédia Escolar, dicionário, imagens, Busca A-Z, Biografias, Reino Animal, Videoteca, Atlas do Mundo, Descubra o Brasil, Notícias',
            },
          ]}
        />
        <HeaderContainer />
        {children}
        <Footer />
      </Fragment>
    );
  }
}

DefaultTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultTemplate;
