import { WebStats } from '../constants';

class AnalyticsApi {
  static writeStat() {
    try {
      document.addEventListener('DOMContentLoaded', () => {
        if (window.writeStat && typeof window.writeStat === 'function') {
          window.writeStat('accountId', {
            domain: WebStats.HOST,
            hostnameOverride: WebStats.HOSTNAME_OVERRIDE,
            accountId: WebStats.ACCOUNT_ID,
          });
        }
      });
    } catch (error) {
      console.error('EB Webstats problem.');
      console.error(error);
    }
  }
}

export default AnalyticsApi;
