import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem, Media } from 'reactstrap';
import ResultsThumbnail from './ResultsThumbnail/ResultsThumbnail';
import MediaOverlayLink from '../MediaOverlayLink/MediaOverlayLink';
import Utilities from '../../services/Utilities';
import { ApplicationVars, Endpoint } from '../../constants';

const ResultsVideoItem = ({ item }) => (
  <ListGroupItem className="ResultsVideoItem ResultsList--item">
    <Media className="row">
      {item.video && (
        <Media left top className="col-12 col-sm-6 col-lg-4 result-item-thumbnail-wrapper">
          <MediaOverlayLink assembly={item}>
            <ResultsThumbnail
              alt={item.caption}
              className="result-item-thumbnail video-item-thumbnail"
              duration={Utilities.formatDuration(item.video.duration)}
              isVideoThumbnail
              src={Endpoint.CDN_URL + ApplicationVars.RESULTS_LIST_THUMBNAIL_SIZE + item.video.videoPoster.filename}
              title={item.video.title}
            />
          </MediaOverlayLink>
        </Media>
      )}
      <Media body className="col-12 col-sm-6 col-lg-8">
        <Media left top>
          <h2 className="media-heading ResultsVideoItem--title">
            <MediaOverlayLink assembly={item}>{item.title}</MediaOverlayLink>
          </h2>
        </Media>
        <p className="ResultsVideoItem--item-caption" dangerouslySetInnerHTML={{ __html: item.caption }} />
      </Media>
    </Media>
  </ListGroupItem>
);

ResultsVideoItem.propTypes = {
  item: PropTypes.shape().isRequired,
};

ResultsVideoItem.defaultProps = {};

export default ResultsVideoItem;
