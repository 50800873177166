import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { AuthType, CompiledPath } from '../../constants';
import AuthUtils from '../../utils/auth-utils';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';

const PrivateRoute = ({ component: Component, isAuth, redirectPath, ...restOfprops }) => {
  const lastAuthUser = AuthUtils.getLastAuthUser();
  const [showLoadingPage, setShowLoadingPage] = useState(true);

  /**
   * We want to show the loading page component temporarily (500 milliseconds) to give enough time for the user
   * to get authenticated in the background if refresh token is found is local storage.
   *
   * This will prevent the flashing/glimpse of the signin page during hard reloads on private routes.
   */
  useEffect(() => {
    let showLoadingPageTimer = null;

    if (isAuth) {
      setShowLoadingPage(false);
    } else {
      showLoadingPageTimer = setTimeout(() => setShowLoadingPage(false), 500);
    }

    return () => clearTimeout(showLoadingPageTimer);
  }, [isAuth]);

  if (showLoadingPage) {
    return <LoadingPage />;
  }

  return (
    <Route
      {...restOfprops}
      render={props =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: redirectPath, state: { from: props.location.pathname, lastAuthUser } }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuth: PropTypes.bool.isRequired,
  redirectPath: PropTypes.string,
};

PrivateRoute.defaultProps = {
  redirectPath: CompiledPath.AUTH({ authType: AuthType.AUTH_SIGNIN }),
};

export default PrivateRoute;
