import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HelpPage from './HelpPage';
import HelpPageHiearchy from './HelpPageConstants';
import { constructPageTitle } from '../../utils/browseUtils';
import '../BrowseThemePage/BrowseThemePage.scss';

class HelpPageContainer extends Component {
  constructor(props) {
    // Determine which props are passed into the page,
    // these can be up to three levels deep, we are calling
    // these theme, subject, topic.
    super(props);

    const { match } = props;
    const { theme, subject, topic } = match.params;

    // We need at least a theme browseId to get started. If the user just
    // goes to help, then we automatically load the first one.

    this.state = {
      browseId: theme,
      subject,
      topic,
      helpHiearchy: null,
      pageTitle: '',
      animateStructureListAndResultsList: '',
    };
  }

  componentDidMount() {
    /*--
      There are no ids to send for Help as it is only the
      one and it is hard coded. They did not want a menu
      of coins at the top.
    --*/

    const HelpHiearchy = HelpPageHiearchy;
    const { browseId, subject, topic } = this.state;

    this.setState(
      {
        helpHiearchy: HelpHiearchy,
        pageTitle: constructPageTitle(HelpHiearchy, browseId, subject, topic),
      },
      () => {
        this.computeValues(HelpHiearchy);
      }
    );
  }

  static getDerivedStateFromProps(props, state) {
    const { match } = props;
    const { params } = match;
    const { theme, subject, topic } = params;
    const { helpHiearchy } = state;

    if (theme !== state.browseId || subject !== state.subject || topic !== state.topic) {
      return {
        browseId: theme,
        topic,
        subject,
        pageTitle: constructPageTitle(helpHiearchy, 1, subject, topic),
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { match } = prevProps;
    const { params } = match;
    const { theme, subject, topic } = params;

    const { browseId, subject: subjectId, topic: topicId } = this.state;

    if (theme !== browseId || subject !== subjectId || topic !== topicId) {
      this.updateHiearchy();
    }
  }

  updateHiearchy() {
    const { match } = this.props;
    const { params } = match;
    const { subject } = params;

    let parentId = null;

    if (subject) {
      parentId = +subject;
    }

    this.setState({ parentId });
  }

  computeValues() {
    const { match } = this.props;
    const { params } = match;
    const { subject } = params;

    let parentId = null;

    if (subject) {
      parentId = +subject;
    }

    this.setState({
      animateStructureListAndResultsList: 'fadeIn',
      parentId,
    });
  }

  render() {
    const { animateStructureListAndResultsList, helpHiearchy, parentId, pageTitle } = this.state;
    const { match } = this.props;

    return (
      <HelpPage
        animateStructureListAndResultsList={animateStructureListAndResultsList}
        parentId={parentId}
        match={match}
        helpHiearchy={helpHiearchy}
        pageTitle={pageTitle}
      />
    );
  }
}

HelpPageContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      theme: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

HelpPageContainer.defaultProps = {};

export default HelpPageContainer;
