import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Header from './Header';
import { RoutePath } from '../../constants';
import { authActions } from '../../pages/Auth/Auth.dux';
import { myBritannicaActions } from '../../pages/MyBritannicaPage/MyBritannica.dux';
import useWithStore from '../../hocs/useWithStore.hoc';

class HeaderContainer extends Component {
  static isHelpNavLinkActiveHandler(match, location) {
    return location.pathname.split('/')[2] === RoutePath.HELP.split('/')[1];
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      showSignoutIFrame: false,
    };

    this.onSignoutHandler = this.onSignoutHandler.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  // --- Lifecycle methods

  static getDerivedStateFromProps(props, state) {
    // Close the menu if we're transitioning to a new route

    if (props.location.pathname !== state.pathname) {
      return {
        isOpen: false,
        pathname: props.location.pathname,
      };
    }

    return null;
  }

  // --- Custom methods

  onSignoutHandler() {
    const { authSignoutHandler, setCollection, setCollections } = this.props;

    setCollection(null);
    setCollections(null);
    authSignoutHandler();

    // ** Hacky Solution ** Because Cognito does't support Google's "prompt=select_account"
    // Other people having the same issue: https://github.com/aws/amazon-cognito-auth-js/issues/68 && https://stackoverflow.com/questions/50623919/aws-cognito-with-google-federated-identity-logs-me-in-with-the-last-user
    // open hidden iframe to call cognito logout endpoint.
    // will kill federation identity provider session (Google).
    this.setState({ showSignoutIFrame: true });
  }

  toggle() {
    this.setState(previousState => ({ isOpen: !previousState.isOpen }));
  }

  // --- Render

  render() {
    const { allFavoritesCollection, match, auth, location } = this.props;
    const { isOpen, showSignoutIFrame } = this.state;
    const { onSignoutHandler } = this;
    const { isHelpNavLinkActiveHandler } = HeaderContainer;

    return (
      <Header
        allFavoritesCollection={allFavoritesCollection}
        showSignoutIFrame={showSignoutIFrame}
        isOpen={isOpen}
        toggle={this.toggle}
        auth={auth}
        isHelpNavLinkActive={isHelpNavLinkActiveHandler}
        isSearchVisible={
          match.path !== RoutePath.HOME && match.path !== RoutePath.SEARCH && match.path !== RoutePath.MEDIA_OVERLAY
        }
        location={location}
        onAuthSignout={onSignoutHandler}
      />
    );
  }
}

HeaderContainer.propTypes = {
  allFavoritesCollection: PropTypes.shape(),
  auth: PropTypes.shape().isRequired,
  authSignoutHandler: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  match: PropTypes.objectOf(PropTypes.any),
  setCollection: PropTypes.func.isRequired,
  setCollections: PropTypes.func.isRequired,
};

HeaderContainer.defaultProps = {
  allFavoritesCollection: null,
  match: null,
};

const mapStateToProps = state => ({
  auth: state.auth,
  allFavoritesCollection:
    !state.myBritannica.collections || state.myBritannica.collections instanceof Error
      ? null
      : state.myBritannica.collections.find(collection => collection.isAllFavoritesCollection),
});

const mapActionsToProps = {
  authSignoutHandler: authActions.authSignout,
  setCollection: myBritannicaActions.setCollection,
  setCollections: myBritannicaActions.setCollections,
};

export default useWithStore(mapStateToProps, mapActionsToProps)(withRouter(HeaderContainer));
