import { get as getCookie, remove as removeCookie } from 'js-cookie';
import { AWSCognito, Endpoint } from '../constants';

class AuthUtils {
  static getAuthOptions() {
    const { accessToken, refreshToken } = AuthUtils.getCognitoTokens();

    return {
      auth: {
        accessToken,
        refreshToken,
        refreshTokenPath: `${Endpoint.BACKEND_API}/individual/refresh`,
      },
    };
  }

  static getCognitoTokens() {
    let lastAuthUser = sessionStorage.getItem(`${AWSCognito.IDENTITY}.LastAuthUser`);

    const cookieAccessToken = getCookie('auth'); // check for accessToken in cookies
    const cookieRefreshToken = getCookie('refr'); // check for refreshToken in cookies

    // Set accessToken && refreshToken in sessionStorage if found in cookies and make sure to remove them from cookies.
    if (cookieAccessToken && cookieRefreshToken) {
      lastAuthUser = 'ltiUser';

      sessionStorage.setItem(`${AWSCognito.IDENTITY}.${lastAuthUser}.accessToken`, cookieAccessToken);
      sessionStorage.setItem(`${AWSCognito.IDENTITY}.${lastAuthUser}.refreshToken`, cookieRefreshToken);
      sessionStorage.setItem(`${AWSCognito.IDENTITY}.LastAuthUser`, lastAuthUser);
      removeCookie('auth', { domain: '.britannica.com.br' });
      removeCookie('refr', { domain: '.britannica.com.br' });
    }

    const accessToken = sessionStorage.getItem(`${AWSCognito.IDENTITY}.${lastAuthUser}.accessToken`);
    const refreshToken = sessionStorage.getItem(`${AWSCognito.IDENTITY}.${lastAuthUser}.refreshToken`);

    return { accessToken, refreshToken };
  }

  static getLastAuthUser() {
    return sessionStorage.getItem(`${AWSCognito.IDENTITY}.LastAuthUser`);
  }

  static removeCognitoTokens() {
    const lastAuthUser = sessionStorage.getItem(`${AWSCognito.IDENTITY}.LastAuthUser`);

    sessionStorage.removeItem(`${AWSCognito.IDENTITY}.${lastAuthUser}.accessToken`);
    sessionStorage.removeItem(`${AWSCognito.IDENTITY}.${lastAuthUser}.refreshToken`);
    sessionStorage.removeItem(`${AWSCognito.IDENTITY}.${lastAuthUser}.clockDrift`);
    sessionStorage.removeItem(`${AWSCognito.IDENTITY}.${lastAuthUser}.idToken`);
    sessionStorage.removeItem(`${AWSCognito.IDENTITY}.${lastAuthUser}.userData`);
  }

  static setCognitoTokens({ accessToken }) {
    const lastAuthUser = sessionStorage.getItem(`${AWSCognito.IDENTITY}.LastAuthUser`);

    sessionStorage.setItem(`${AWSCognito.IDENTITY}.${lastAuthUser}.accessToken`, accessToken);
  }

  static setSessionStorage(storageData) {
    const storageDataKeys = Object.keys(storageData);
    const numOfStorageDataKeys = storageDataKeys.length;

    for (let i = 0; i < numOfStorageDataKeys; i += 1) {
      const storageDataKey = storageDataKeys[i];
      const storageDataValue = storageData[storageDataKey];

      sessionStorage.setItem(storageDataKey, storageDataValue);
    }
  }

  static moveLocalStorageToSessionStorage(username) {
    const lastAuthUserKey = `${AWSCognito.IDENTITY}.LastAuthUser`;
    const userDataKey = `${AWSCognito.IDENTITY}.${username}.userData`;
    const idTokenKey = `${AWSCognito.IDENTITY}.${username}.idToken`;
    const refreshTokenKey = `${AWSCognito.IDENTITY}.${username}.refreshToken`;
    const clockDriftKey = `${AWSCognito.IDENTITY}.${username}.clockDrift`;
    const accessTokenKey = `${AWSCognito.IDENTITY}.${username}.accessToken`;

    const lastAuthUserValue = localStorage.getItem(lastAuthUserKey);
    const userDataValue = localStorage.getItem(userDataKey);
    const idTokenValue = localStorage.getItem(idTokenKey);
    const refreshTokenValue = localStorage.getItem(refreshTokenKey);
    const clockDriftValue = localStorage.getItem(clockDriftKey);
    const accessTokenValue = localStorage.getItem(accessTokenKey);

    if (
      lastAuthUserValue &&
      userDataValue &&
      idTokenValue &&
      refreshTokenValue &&
      clockDriftValue &&
      accessTokenValue
    ) {
      const localStorageData = {};

      localStorageData[lastAuthUserKey] = lastAuthUserValue;
      localStorageData[userDataKey] = userDataValue;
      localStorageData[idTokenKey] = idTokenValue;
      localStorageData[refreshTokenKey] = refreshTokenValue;
      localStorageData[clockDriftKey] = clockDriftValue;
      localStorageData[accessTokenKey] = accessTokenValue;

      this.setSessionStorage(localStorageData);

      localStorage.removeItem(lastAuthUserKey);
      localStorage.removeItem(userDataKey);
      localStorage.removeItem(idTokenKey);
      localStorage.removeItem(refreshTokenKey);
      localStorage.removeItem(clockDriftKey);
      localStorage.removeItem(accessTokenKey);
    }
  }
}

export default AuthUtils;
