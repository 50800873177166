import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Navbar, Nav, NavItem, NavLink } from 'reactstrap';

import './Footer.scss';
import escolaFtrBrand from '../../assets/images/escola_ftr_brand.png';
import escolaBrandingFooter from '../../assets/images/escola_branding_footer.png';

const Footer = () => (
  <Navbar className="Footer" color="primary" dark expand="lg" tag="footer">
    <Container className="container">
      <div className="footer-branding-container">
        <img className="logo" src={escolaFtrBrand} alt="Britannica logo" />
        <NavLink target="_blank" rel="noopener noreferrer" href="https://www.mec.gov.br/">
          <img className="logo sponsor" src={escolaBrandingFooter} alt="Britannica Sponsor branding logo" />
        </NavLink>
      </div>

      <div className="footer-links-container">
        <Nav navbar className="footer-links">
          <NavItem>
            <NavLink rel="noopener noreferrer" href="mailto:sugestoes_britannica@eb.com">
              Comentários
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://corporate.britannica.com/termos_de_uso.html"
            >
              Copyright/Termos de uso
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink target="_blank" rel="noopener noreferrer" href="https://corporate.britannica.com/privacy.html">
              Política de privacidade
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink target="_blank" rel="noopener noreferrer" href="https://britannica.com.br/atividades-didaticas/">
              Recursos para o Professor
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink target="_blank" rel="noopener noreferrer" href="https://britannica.com.br/britannica-escola/">
              Saiba mais
            </NavLink>
          </NavItem>
        </Nav>
        <div className="footer-copyright text-lg-right">
          <span className="footer-copyright-text text-color--dark">
            © {new Date().getFullYear()} Encyclopædia Britannica, Inc.
          </span>
        </div>
      </div>
    </Container>
  </Navbar>
);

export default withRouter(Footer);
