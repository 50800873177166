import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { Card, CardBody } from 'reactstrap';
import PageContent from '../../components/PageContent/PageContent';
import AccordionNavigationContainer from '../../components/AccordionNavigation/AccordionNavigationContainer';
import HelpPageRoutes from './HelpPage.routes';
import DefaultTemplate from '../templates/DefaultTemplate';
import { BrowseType, CompiledPath } from '../../constants';
import './HelpPage.scss';

const HelpPage = props => {
  const {
    animateStructureListAndResultsList,
    helpHiearchy,
    match: {
      params: { theme, subject, topic },
    },
    parentId,
    pageTitle,
  } = props;

  return (
    <DefaultTemplate>
      <PageContent pageTitle="Ajuda" className="HelpPage mb-5">
        <Helmet>
          <title>{pageTitle}</title>
          <meta
            name="description"
            content="Nesta página, você pode encontrar informações sobre as funcionalidades do portal Britannica Escola (opções de pesquisa, jogos, ferramentas e widget), além de dados para contato e informações sobre requisitos técnicos, acessibilidade e políticas de conteúdo."
          />
          <link rel="canonical" href={window.location.origin + CompiledPath.BROWSE_HELP({ theme, subject, topic })} />
        </Helmet>
        <div className="container">
          <section className={classNames('BrowseThemePage--section mt-4 animated', animateStructureListAndResultsList)}>
            <div className="row mt-4">
              <div className="BrowseThemePage--structure-list-col col-md-3">
                <AccordionNavigationContainer
                  hiearchy={helpHiearchy}
                  rootBrowseId={parentId}
                  browseType={BrowseType.HELP}
                  // mobileDropdownLabel="Tópicos"
                  {...props}
                />
              </div>

              <div className=" col-md-9">
                <Card>
                  <CardBody>
                    {/* <Loading isLoading={!content}> */}
                    <HelpPageRoutes />
                    {/* </Loading> */}
                  </CardBody>
                </Card>
              </div>
            </div>
          </section>
        </div>
      </PageContent>
    </DefaultTemplate>
  );
};

HelpPage.propTypes = {
  animateStructureListAndResultsList: PropTypes.string.isRequired,
  parentId: PropTypes.number,
  helpHiearchy: PropTypes.shape(),
  pageTitle: PropTypes.string,
  match: PropTypes.shape().isRequired,
};

HelpPage.defaultProps = {
  parentId: null,
  helpHiearchy: null,
  pageTitle: '',
};

export default HelpPage;
