import { AuthType } from '../../constants';

export const Auth = {
  SIGNIN: 'Fazer login',
  SIGNIN_SUBHEADER: 'Entrar na sua conta pessoal',
  SIGNIN_ERROR_MSG: 'Nome de usuário ou senha incorretos.',
  SIGNOUT: 'Sair',
  SIGNUP_BUTTON: 'Criar conta',
  SIGNUP_LINK: 'Criar uma conta',
  SIGNUP_SUBHEADER: 'Criar uma conta pessoal',
  SIGNUP_ERROR_MSG: 'Esse usuário já existe.',
  FORGOT_SUBHEADER: 'Alterar senha',
  FORGOT_PASSWORD_RECOVER_TEXT: 'Se você não consegue alterar a senha, entre em ',
  FORGOT_PASSWORD_BUTTON: 'Alterar senha',
  FORGOT_PASSWORD_LINK: 'Esqueceu a senha?',
  FORGOT_ERROR_MSG: 'Os dados que você digitou não coincidem com os dados que temos. A senha não pôde ser alterada.',
  FORGOT_SIGNUP_TEXT: 'Ainda não tem uma conta do Britannica Escola? ',
  FORGOT_SIGNUP_LINK: 'Crie sua conta.',
  GOOGLE_BUTTON: 'Fazer login com o Google',
  GOOGLE_EXPLAINER: 'Você tem uma conta do Google? Use seu e-mail para fazer login.',
  BENEFIT_ONE_SUBHEAD: 'Salve seus recursos favoritos',
  BENEFIT_ONE_TEXT: 'Salve artigos, imagens e outros recursos para conseguir acessá-los mais facilmente.',
  BENEFIT_TWO_SUBHEAD: 'Organize seu trabalho',
  BENEFIT_TWO_TEXT:
    'Crie coleções com os temas que quiser para agrupar seus recursos favoritos. Você também pode incluir anotações.',
  BENEFIT_THREE_SUBHEAD: 'Crie sua conta gratuitamente',
  BENEFIT_THREE_TEXT: 'Você pode aproveitar as vantagens de sua conta pessoal de maneira totalmente gratuita.',
};

export const AuthForm = {
  USERNAME_PLACEHOLDER: 'Nome de usuário',
  USERNAME_IS_REQUIRED: 'Digite seu nome de usuário.',
  PASSWORD_PLACEHOLDER: 'Senha',
  PASSWORD_IS_REQUIRED: 'Digite sua senha.',
  PASSWORD_MIN_ERROR_MSG: 'A senha deve ter pelo menos {6} caracteres. Por favor, escolha outra.',
  PASSWORD_CONFIRM_PLACEHOLDER: 'Confirmar senha',
  PASSWORD_CONFIRM_IS_REQUIRED: 'Confirme sua senha.',
  PASSWORD_CONFIRM_NO_MATCH_ERROR_MSG: 'As senhas não coincidem. Por favor, tente novamente.',
  FIRST_NAME_PLACEHOLDER: 'Nome',
  FIRST_NAME_IS_REQUIRED: 'Digite seu nome.',
  LAST_NAME_PLACEHOLDER: 'Sobrenome',
  LAST_NAME_IS_REQUIRED: 'Digite seu sobrenome.',
  EMAIL_PLACEHOLDER: 'E-mail (opcional)',
  EMAIL_INVALID_ERROR_MSG: 'E-mail inválido. Por favor, tente novamente.',
  SECURITY_QUESTION_IS_REQUIRED: 'Escolha uma pergunta de segurança.',
  SECURITY_ANSWER_PLACEHOLDER: 'Resposta da pergunta de segurança',
  SECURITY_ANSWER_IS_REQUIRED: 'Digite a resposta.',
  NEW_PASSWORD_PLACEHOLDER: 'Nova senha',
  NEW_PASSWORD_ERROR_MSG: 'Digite sua nova senha.',
  NEW_PASSWORD_CONFIRM_PLACEHOLDER: 'Confirmar nova senha',
  NEW_PASSWORD_CONFIRM_ERROR_MSG: 'Confirme sua nova senha.',
};

export const AuthPrevPageStatus = {
  newAccountCreated: 'Conta criada com sucesso. Digite seu nome de usuário e senha para fazer login.',
  passwordHasBeenReset: 'Senha alterada com sucesso. Digite seu nome de usuário e senha para fazer login.',
};

export default {
  [AuthType.AUTH_SIGNIN]: {
    header: 'Fazer login no Britannica Escola',
    description: null,
    descriptionLinkText: null,
    helmetTitle: Auth.SIGNIN,
  },
  [AuthType.AUTH_SIGNUP]: {
    header: 'Fazer login no Britannica Escola',
    description:
      'Preencha os campos abaixo para criar sua conta pessoal do Britannica Escola. Se você já tem uma conta, ',
    descriptionLinkText: 'faça login',
    helmetTitle: Auth.SIGNUP_BUTTON,
  },
  [AuthType.AUTH_FORGOT_PASSWORD]: {
    header: 'Fazer login no Britannica Escola',
    description: 'Siga as instruções abaixo para recuperar sua conta. Se você já tem os dados necessários, ',
    descriptionLinkText: 'faça login',
    helmetTitle: Auth.FORGOT_PASSWORD_BUTTON,
  },
};

export const securityQuestions = {
  '0': 'Pergunta de segurança',
  '1': 'Qual é o nome do seu primo mais velho?',
  '2': 'Quando é o aniversário da sua mãe?',
  '3': 'Quando é o aniversário do seu pai?',
  '4': 'Como você chama o seu avô ou a sua avó?',
  '5': `Em que cidade você nasceu?`,
  '6': `Qual é o nome do seu bicho de estimação?`,
};
