import React from 'react';
import LoadingContainer from '../../components/Loading/LoadingContainer';
import PageContent from '../../components/PageContent/PageContent';

const LoadingPage = () => (
  <PageContent className="d-flex align-items-center">
    <LoadingContainer isLoading />
  </PageContent>
);

export default LoadingPage;
