import React from 'react';
import MediaQuery from 'react-responsive';
import { Route, Switch } from 'react-router-dom';

import { ApplicationVars, Breakpoints, RoutePath, Routes } from './constants';
import { AlphaTypes } from './pages/BrowseAlphaPage/BrowseAlphaPage-constants';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import ArticlePageContainer from './pages/ArticlePage/ArticlePage.lazy';
import AuthPage from './pages/Auth/AuthPage.lazy';
import BrowseAlphaPage from './pages/BrowseAlphaPage/BrowseAlphaPage.lazy';
import BrowseAnimalPage from './pages/BrowseAnimalPage/BrowseAnimalPage.lazy';
import BrowseAtlasPage from './pages/BrowseAtlasPage/BrowseAtlasPage.lazy';
import BrowseGamePage from './pages/BrowseGamePage/BrowseGamePage.lazy';
import BrowseThemePage from './pages/BrowseThemePage/BrowseThemePage.lazy';
import BrowseVideoPage from './pages/BrowseVideoPage/BrowseVideoPage.lazy';
import ErrorPage from './pages/ErrorPage/ErrorPage.lazy';
import HomePage from './pages/HomePage/HomePage.lazy';
import HelpPageContainer from './pages/HelpPage/HelpPageContainer';
import MyBritannicaPage from './pages/MyBritannicaPage/MyBritannicaPage.lazy';
import SearchResultsPage from './pages/SearchResultsPage/SearchResultsPage.lazy';
import SearchWidgetContainer from './pages/SearchWidget/SearchWidgetContainer';
import SharedCollectionPage from './pages/SharedCollectionPage/SharedCollectionPage.lazy';
import useWithStore from './hooks/useWithStore.hook';

import SamplePage from './pages/SamplePage/SamplePage.lazy';
import TestPage from './pages/TestPage/TestPage.lazy';

const AppRoutes = () => {
  const { store } = useWithStore(state => ({
    auth: state.auth,
  }));

  return (
    <Switch>
      {/* private routes */}
      <PrivateRoute path={Routes.MY_BRITANNICA.path} component={MyBritannicaPage} isAuth={store.auth.isAuthenticated} />

      {/* public routes */}
      <Route path={RoutePath.BROWSE_HELP} component={HelpPageContainer} />
      <Route exact path={RoutePath.HOME} component={HomePage} />
      <Route path={RoutePath.MEDIA_OVERLAY} component={HomePage} />
      <Route path={RoutePath.ARTICLE} component={ArticlePageContainer} />
      <Route exact path={RoutePath.BROWSE_ATLAS} component={BrowseAtlasPage} />
      <Route
        path={RoutePath.BROWSE_THEME}
        render={props => (
          <MediaQuery maxWidth={Breakpoints.MD - 1}>
            {useWindow => <BrowseThemePage {...props} useWindow={useWindow} />}
          </MediaQuery>
        )}
      />
      <Route path={RoutePath.BROWSE_GAMES} component={BrowseGamePage} />
      <Route
        path={RoutePath.BROWSE_VIDEOS}
        render={props => (
          <MediaQuery maxWidth={Breakpoints.MD - 1}>
            {useWindow => <BrowseVideoPage {...props} useWindow={useWindow} />}
          </MediaQuery>
        )}
      />
      <Route
        path={RoutePath.BROWSE_ANIMALS}
        render={props => (
          <MediaQuery maxWidth={Breakpoints.MD - 1}>
            {isMobile => <BrowseAnimalPage {...props} isMobile={isMobile} />}
          </MediaQuery>
        )}
      />
      <Route
        path={RoutePath.BROWSE_ALPHA}
        render={props =>
          AlphaTypes[props.match.params.alphaType] ? (
            <MediaQuery maxWidth={Breakpoints.MD - 1}>
              {isMobile => <BrowseAlphaPage {...props} isMobile={isMobile} />}
            </MediaQuery>
          ) : (
            <Route component={ErrorPage} />
          )
        }
      />

      {/* Check to make sure searchQuery exists before loading SearchResultsPageContainer */}
      <Route
        path={RoutePath.SEARCH}
        render={props => {
          const { searchQuery } = props.match.params;

          return searchQuery ? (
            <SearchResultsPage {...props} searchQuery={searchQuery} />
          ) : (
            <Route component={ErrorPage} />
          );
        }}
      />

      <Route path={Routes.SHARED_COLLECTION.path} component={SharedCollectionPage} />

      <Route exact path={RoutePath.SEARCH_WIDGET} component={SearchWidgetContainer} />

      <Route
        path={RoutePath.AUTH}
        render={props => <AuthPage {...props} isAuthenticated={store.auth.isAuthenticated} />}
      />

      {/* Routes that only appear in dev mode */}
      {ApplicationVars.ENV !== 'production' && <Route path={RoutePath.SAMPLE_PAGE} component={SamplePage} />}
      {ApplicationVars.ENV !== 'production' && <Route path={RoutePath.TEST} component={TestPage} />}

      <Route component={ErrorPage} />
    </Switch>
  );
};

export default AppRoutes;
