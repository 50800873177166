import FetchManager from './FetchManager';
import { Endpoint } from '../constants';
import AuthUtils from '../utils/auth-utils';
import { store } from '../store/store';

class MyBritannicaApi extends FetchManager {
  constructor() {
    super();
    this.dispatch = store.dispatch;
  }

  addCollection({ title, description, shared }) {
    return this.fetchWithAuth(
      `${Endpoint.BACKEND_API}/collection`,
      {
        method: 'POST',
        body: JSON.stringify({
          title,
          description,
          shared,
        }),
      },
      AuthUtils.getAuthOptions()
    );
  }

  addCollectionItem(collectionId, { contentType, contentId, note = null }) {
    return this.fetchWithAuth(
      `${Endpoint.BACKEND_API}/collection/${collectionId}/content/item`,
      {
        method: 'POST',
        body: JSON.stringify({
          contentType,
          contentId,
          note,
        }),
      },
      AuthUtils.getAuthOptions()
    );
  }

  deleteCollection(collectionId) {
    return this.fetchWithAuth(
      `${Endpoint.BACKEND_API}/collection/${collectionId}`,
      {
        method: 'DELETE',
      },
      AuthUtils.getAuthOptions()
    );
  }

  deleteCollectionItem(collectionId, favoriteId) {
    return this.fetchWithAuth(
      `${Endpoint.BACKEND_API}/collection/${collectionId}/content/item/${favoriteId}`,
      {
        method: 'DELETE',
      },
      AuthUtils.getAuthOptions()
    );
  }

  getCollection(collectionId) {
    return this.fetchWithAuth(`${Endpoint.BACKEND_API}/collection/${collectionId}`, null, AuthUtils.getAuthOptions());
  }

  getCollections() {
    return this.fetchWithAuth(`${Endpoint.BACKEND_API}/collections`, null, AuthUtils.getAuthOptions());
  }

  getSharedCollection(collectionId) {
    return this.fetch(`${Endpoint.BACKEND_API}/sharedCollection/${collectionId}`);
  }

  reorderCollectionItems(collectionId, reorderedFavoriteIds) {
    return this.fetchWithAuth(
      `${Endpoint.BACKEND_API}/collection/${collectionId}/reorderItems`,
      {
        method: 'PUT',
        body: JSON.stringify({
          reorderedFavoriteIds,
        }),
      },
      AuthUtils.getAuthOptions()
    );
  }

  updateCollection({ title, description, shared }, collectionId) {
    return this.fetchWithAuth(
      `${Endpoint.BACKEND_API}/collection/${collectionId}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          title,
          description,
          shared,
        }),
      },
      AuthUtils.getAuthOptions()
    );
  }

  updateCollectionItem(collectionId, favoriteId, note) {
    return this.fetchWithAuth(
      `${Endpoint.BACKEND_API}/collection/${collectionId}/content/item/${favoriteId}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          note: note.length ? note : null,
        }),
      },
      AuthUtils.getAuthOptions()
    );
  }
}

export default new MyBritannicaApi();
