import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import './ResultsThumbnail.scss';

const ResultsThumbnail = props => {
  const { alt, className, duration, isVideoThumbnail, src, title } = props;

  return (
    <div className={`ResultsThumbnail ${className}`}>
      <div className="ResultsThumbnail-inner-wrapper">
        {isVideoThumbnail && (
          <div className="ResultsThumbnail-video-util-wrapper">
            <FontAwesomeIcon className="ResultsThumbnail-video-play-circle" icon={faPlay} size="2x" />

            {duration && <div className="ResultsThumbnail-video-duration">{duration}</div>}
          </div>
        )}
        <img src={src} title={title} alt={alt} />
      </div>
    </div>
  );
};

ResultsThumbnail.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  duration: PropTypes.string,
  isVideoThumbnail: PropTypes.bool,
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
};

ResultsThumbnail.defaultProps = {
  className: '',
  duration: null,
  isVideoThumbnail: false,
  title: '',
};

export default ResultsThumbnail;
