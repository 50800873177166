import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ListGroupItem, Media } from 'reactstrap';
import ResultsThumbnail from './ResultsThumbnail/ResultsThumbnail';
import { ApplicationVars, Endpoint } from '../../constants';

const ResultsArticleItem = ({ item, routePath, isInvertedTitle }) => (
  <ListGroupItem className="ResultsArticleItem ResultsList--item">
    <Media className="row">
      {item.firstAssembly && (
        <Media
          left
          top
          tag={Link}
          to={`${routePath}${item.urlTitle}/${item.articleId}`}
          className="col-4 col-lg-2 d-none d-md-block result-item-thumbnail-wrapper"
        >
          <ResultsThumbnail
            className="result-item-thumbnail"
            src={Endpoint.CDN_URL + ApplicationVars.RESULTS_LIST_THUMBNAIL_SIZE + item.firstAssembly.image.filename}
            alt={item.firstAssembly.caption || item.firstAssembly.title}
            title={item.firstAssembly.title}
          />
        </Media>
      )}
      <Media body className="col-12 col-md-8 col-lg-10">
        <Media className="result-item-title" left top tag={Link} to={`${routePath}${item.urlTitle}/${item.articleId}`}>
          <h2 className="media-heading">{isInvertedTitle ? item.invertedTitle : item.title}</h2>
        </Media>
        <p dangerouslySetInnerHTML={{ __html: item.gist }} />
      </Media>
    </Media>
  </ListGroupItem>
);

ResultsArticleItem.propTypes = {
  item: PropTypes.shape().isRequired,
  routePath: PropTypes.string.isRequired,
  isInvertedTitle: PropTypes.bool,
};

ResultsArticleItem.defaultProps = {
  isInvertedTitle: true,
};

export default ResultsArticleItem;
