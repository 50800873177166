import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { debounce } from '../../../macros/lodash.macro';
import ContentApi from '../../services/ContentApi';
import { ApplicationVars, RoutePath, SearchType } from '../../constants';
import SearchBoxMobile from './SearchBoxMobile';

class SearchBoxMobileContainer extends Component {
  constructor() {
    super();

    this.state = {
      isModalOpen: false,
      locationKey: null,
      searchValue: '',
      searchResults: null,
    };

    this.onSearchChangeHandler = this.onSearchChangeHandler.bind(this);
    this.onFetchAutocompleteSuggestions = debounce(
      this.onFetchAutocompleteSuggestions.bind(this),
      ApplicationVars.DEBOUNCE_RATE
    );
    this.onToggleModalHandler = this.onToggleModalHandler.bind(this);
    this.onSearchSubmitHandler = this.onSearchSubmitHandler.bind(this);
  }

  static getDerivedStateFromProps(nextProp, prevState) {
    if (nextProp.location.key !== prevState.locationKey) {
      return {
        isModalOpen: false,
        locationKey: nextProp.location.key,
        searchValue: '',
        searchResults: null,
      };
    }

    return null;
  }

  async onFetchAutocompleteSuggestions() {
    const { searchValue } = this.state;

    this.setState({
      searchResults: searchValue
        ? await ContentApi.fetchAutocompleteSuggestions(SearchType.ARTICLES, searchValue, ApplicationVars.PAGE_SIZE)
        : null,
    });
  }

  onSearchChangeHandler(event) {
    const { value } = event.target;

    this.setState({ searchValue: value }, this.onFetchAutocompleteSuggestions);
  }

  onToggleModalHandler() {
    this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen, searchValue: '', searchResults: null }));
  }

  onSearchSubmitHandler(event) {
    event.preventDefault();

    const { history } = this.props;
    const { searchValue } = this.state;

    if (searchValue.length) {
      const path = `${RoutePath.SEARCH.replace('/:searchType/:searchQuery', '')}`;

      history.push({
        pathname: `${path}/${SearchType.ARTICLES}/${searchValue.split(' ').join('+')}`,
      });
    }
  }

  render() {
    const { onSearchChangeHandler, onToggleModalHandler, onSearchSubmitHandler } = this;
    const { isModalOpen, searchResults, searchValue } = this.state;

    return (
      <SearchBoxMobile
        onChange={onSearchChangeHandler}
        onToggleModal={onToggleModalHandler}
        onSearchSubmit={onSearchSubmitHandler}
        isModalOpen={isModalOpen}
        searchResults={searchResults}
        value={searchValue}
      />
    );
  }
}

SearchBoxMobileContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
};

SearchBoxMobileContainer.defaultProps = {
  history: false,
};

export default withRouter(SearchBoxMobileContainer);
