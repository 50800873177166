// Ferramentas
import React from 'react';

const FunctionalityTools = () => (
  <div className="container">
    <h2 className="toc-level-0 text-color--secondary">Ferramentas</h2>

    <h3 className="toc-level-3 text-color--secondary">Imprimir</h3>
    <p>Para imprimir o recurso desejado, clique no ícone da impressora.</p>

    <h3 className="toc-level-3 text-color--secondary">Enviar por e-mail</h3>
    <p>Clique no ícone do envelope para enviar o link da página por e-mail.</p>

    <h3 className="toc-level-3 text-color--secondary">Citar</h3>
    <p>
      Para citar o conteúdo do Britannica Escola em seus trabalhos e projetos, use a ferramenta Citar. Basta clicar no
      ícone, e a ferramenta automaticamente fornecerá as informações para citação, formatadas no estilo{' '}
      <abbr title="Associação Brasileira de Normas Técnicas">ABNT</abbr>.
    </p>
  </div>
);

export default FunctionalityTools;
