import AuthUtils from '../../utils/auth-utils';

// Initial state
const initialState = {
  isAuthenticated: false,
  username: null,
  firstName: null,
  lastName: null,
  sub: null,
  error: null,
  responseReturned: false,
};

// Actions
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAIL = 'AUTH_FAIL';
const AUTH_SIGNOUT = 'AUTH_SIGNOUT';
const AUTH_INITIAL = 'AUTH_INITIAL';

// Action creators
export const authActions = {
  authSuccess: auth => ({
    type: AUTH_SUCCESS,
    payload: auth,
  }),

  authFail: error => ({
    type: AUTH_FAIL,
    payload: error,
  }),

  authSignout: () => ({
    type: AUTH_SIGNOUT,
  }),

  authInitial: payload => ({
    type: AUTH_INITIAL,
    payload,
  }),
};

// Reducer

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      AuthUtils.moveLocalStorageToSessionStorage(action.payload.username);

      return {
        ...state,
        isAuthenticated: true,
        username: action.payload.username,
        firstName: action.payload.givenName,
        lastName: action.payload.familyName,
        sub: action.payload.sub || action.payload.principalId,
        error: null,
        responseReturned: true,
      };
    case AUTH_FAIL:
      return {
        ...initialState,
        error: {
          status: action.payload.status,
          statusText: action.payload.statusText || action.payload.message,
        },
        responseReturned: true,
      };
    case AUTH_SIGNOUT:
      AuthUtils.removeCognitoTokens();

      return {
        ...initialState,
        responseReturned: true,
      };
    case AUTH_INITIAL:
      return {
        ...initialState,
        ...action.payload,
      };
    default:
      return state;
  }
};
