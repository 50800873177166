import FetchManager from './FetchManager';
import { Endpoint } from '../constants';
import AuthUtils from '../utils/auth-utils';

class PrivateContentApi extends FetchManager {
  getUserAttributes() {
    return this.fetchWithAuth(`${Endpoint.BACKEND_API}/individual/attributes`, null, AuthUtils.getAuthOptions());
  }
}

export default new PrivateContentApi();
