class Utilities {
  static formatDuration(time) {
    if (!time) {
      return null;
    }

    const d = Number(time);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    let duration = '';

    if (h > 0) {
      duration = `${h}:${m < 10 ? '0' : ''}`;
    }

    duration += `${m}:${s < 10 ? '0' : ''}`;
    duration += s;

    return duration;
  }

  static scrollTop() {
    window.scrollTo(0, 0);
  }

  static truncateStringByCharCount(str, charCount) {
    if (str.length > charCount) {
      return `${str.substr(0, charCount)}...`;
    }

    return str;
  }

  static cleanSearchQuery(searchQuery) {
    if (searchQuery && searchQuery.length) {
      return searchQuery.split('+').join(' ');
    }

    return '';
  }
}

export default Utilities;
