import FetchManager from './FetchManager';
import { Endpoint, SearchType, APIKeys, ApplicationVars } from '../constants';

export default class ContentApi extends FetchManager {
  static async fetchArticle(articleId) {
    return fetch(`${Endpoint.BACKEND_API}/article/${articleId}`)
      .then(response => response.json())
      .catch(error => error);
  }

  static async fetchArticlePrint(articleId) {
    return fetch(`${Endpoint.BACKEND_API}/article/${articleId}/print`)
      .then(response => response.json())
      .catch(error => error);
  }

  fetchRandomArticle() {
    return this.fetch(`${Endpoint.BACKEND_API}/articles/random/preview`).catch(error => error);
  }

  fetchRandomVideo() {
    return this.fetch(`${Endpoint.BACKEND_API}/video/dyk/random`).catch(error => error);
  }

  fetchFolhaFeed() {
    return this.fetch(`${Endpoint.BACKEND_API}/feed/folha`).catch(error => error);
  }

  static async fetchBrowseStructureByBrowseId(browseId) {
    return fetch(`${Endpoint.BACKEND_API}/browse/theme/subject/${browseId}?expandChildren=true`).then(response =>
      response.json()
    );
  }

  static async fetchArticlesByBrowseId(browseId, pageNumber = 1, pageSize = ApplicationVars.PAGE_SIZE) {
    return fetch(
      `${Endpoint.BACKEND_API}/browse/theme/subject/${browseId}/articles?pageSize=${pageSize}&pageNumber=${pageNumber}`
    ).then(response => {
      return response.json();
    });
  }

  static fetchAlphaStructureByTopic(topicPath, pageSize = ApplicationVars.PAGE_SIZE) {
    return fetch(`${Endpoint.BACKEND_API}${topicPath}?pageSize=${pageSize}`).then(response => response.json());
  }

  static fetchAlphaEntriesByTopic(topicPath, searchQuery, pageNumber, pageSize = ApplicationVars.PAGE_SIZE) {
    return fetch(
      `${Endpoint.BACKEND_API}${topicPath}/entries?query=${searchQuery}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    ).then(response => response.json());
  }

  static async fetchGameBrowseStructureByBrowseId(browseId) {
    return fetch(`${Endpoint.BACKEND_API}/browse/theme/game/${browseId}?expandChildren=true`).then(response =>
      response.json()
    );
  }

  static async fetchGameListByBrowseId(browseId, pageNumber = 1, pageSize = 1000) {
    return fetch(
      `${Endpoint.BACKEND_API}/browse/theme/game/${browseId}/games?pageNumber=${pageNumber}&pageSize=${pageSize}&`
    ).then(response => response.json());
  }

  static async fetchVideosByBrowseId(browseId, pageNumber = 1, pageSize = ApplicationVars.PAGE_SIZE) {
    return fetch(
      `${
        Endpoint.BACKEND_API
      }/browse/theme/subject/${browseId}/assemblies?pageSize=${pageSize}&pageNumber=${pageNumber}`
    ).then(response => response.json());
  }

  /**
   * @param  {Integer} browseId
   * @param  {} {returnfetch(`${Endpoint.BACKEND_API}/browse/theme/animalkingdom/${browseId}`
   * @param  {} .then(response=>response.json(
   */

  static async fetchAnimalKingdomSubjectListByGroupId(browseId) {
    return fetch(`${Endpoint.BACKEND_API}/browse/theme/animalkingdom/${browseId}`).then(response => response.json());
  }

  /**
   * @param  {Integer} browseId
   * @param  {Integer} pageNumber=1
   * @param  {Integer} pageSize=ApplicationVars.PAGE_SIZE
   * @param  {} {returnfetch(
   * @param  {} .then(response=>response.json(
   */
  static async fetchAnimalKingdomArticlesByGroupId(browseId, pageNumber = 1, pageSize = ApplicationVars.PAGE_SIZE) {
    return fetch(
      `${
        Endpoint.BACKEND_API
      }/browse/theme/animalkingdom/${browseId}/articles?pageNumber=${pageNumber}&pageSize=${pageSize}`
    ).then(response => response.json());
  }

  static async fetchAutocompleteSuggestions(searchType, searchQuery, maxResults = 5) {
    // based on searchType make the proper api calls.
    return searchType !== SearchType.DICTIONARY
      ? fetch(`${Endpoint.BACKEND_API}/search/article/autocomplete?pageSize=${maxResults}&query=${searchQuery}`).then(
          response => response.json()
        )
      : fetch(`${Endpoint.BACKEND_API}/search/dictionary?autocomplete=true&query=${searchQuery}`).then(response =>
          response.json()
        );
  }

  static async fetchAtlasLocationInformation(locations) {
    return locations.length > 1
      ? fetch(
          `${Endpoint.BACKEND_API}/place/?${locations[0].type}=${locations[0].iso}&${locations[1].type}=${
            locations[1].iso
          }`
        ).then(response => response.json())
      : fetch(`${Endpoint.BACKEND_API}/place/?${locations[0].type}=${locations[0].iso}`).then(response =>
          response.json()
        );
  }

  static async fetchPlaceByLatitudeAndLongitude(latitude, longitude) {
    return fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${
        APIKeys.GOOGLE_PLACES
      }&language=pt-BR&result_type=country|administrative_area_level_1`
    ).then(response => response.json());
  }

  static async fetchPlaceByPlaceId(placeId = 'ChIJmd5kZkdvABURmU4mUQdbKI0') {
    return fetch(
      `https://maps.googleapis.com/maps/api/place/details/json?${placeId}$&key=${APIKeys.GOOGLE_PLACES}&language=pt-BR`
    ).then(response => response.json());
  }

  fetchAnimalKingdomSubjectListById(animalKingdomSubjectId, removeLinks = false) {
    return this.fetch(
      `${Endpoint.BACKEND_API}/browse/theme/animalkingdom/${animalKingdomSubjectId}?removeLinks=${removeLinks}`
    ).catch(error => error);
  }

  fetchSearchEntriesBySearchType(requestObj) {
    const { searchType, searchQuery, pageNumber = 1, pageSize = ApplicationVars.PAGE_SIZE } = requestObj;
    const url = `${
      Endpoint.BACKEND_API
    }/search/${searchType}?query=${searchQuery}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

    return this.fetch(url).catch(error => error);
  }

  fetchAssemblyByAssemblyId(assemblyId) {
    return this.fetch(`${Endpoint.BACKEND_API}/assembly/${assemblyId}`).catch(error => error);
  }
}

/*
  TODO: Need to use instance of the ContentApi instead of the class itself (A.B.)

  Export instance of ContentApi.
  For now we are exporting both the Class ContentApi (default) and an instance of it.
  Currently the instance is only being used in SearchResultsPageContainer & HomePageContainer.
  We are close to launching Escola and don't want to make a global change at the moment (Feb/4/19 A.B.).
  We will eventually use the instance of the ContentApi instead of the class itself to make use of the FetchManager that it extends off of.
*/
export const contentApi = new ContentApi();
