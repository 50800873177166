import { orderBy } from '../../../macros/lodash.macro';

// Initial state
const initialState = {
  collection: null,
  collections: null,
  errorMessage: null,
};

// Actions
const ADD_COLLECTION = 'ADD_COLLECTION';
const ADD_COLLECTION_ITEM = 'ADD_COLLECTION_ITEM';
const DELETE_COLLECTION = 'DELETE_COLLECTION';
const DELETE_COLLECTION_ITEM = 'DELETE_COLLECTION_ITEM';
const SET_ERROR_MESSAGE = 'ERROR_MESSAGE';
const SET_COLLECTION = 'SET_COLLECTION';
const SET_COLLECTIONS = 'SET_COLLECTIONS';
const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
const UPDATE_COLLECTION_ITEM = 'UPDATE_COLLECTION_ITEM';

// Action creators
export const myBritannicaActions = {
  addCollection: newCollection => ({
    type: ADD_COLLECTION,
    payload: newCollection,
  }),

  addCollectionItem: updatedCollection => ({
    type: ADD_COLLECTION_ITEM,
    payload: updatedCollection,
  }),

  deleteCollection: deletedCollectionId => ({
    type: DELETE_COLLECTION,
    payload: deletedCollectionId,
  }),

  deleteCollectionItem: payload => ({
    type: DELETE_COLLECTION_ITEM,
    payload,
  }),

  setErrorMessage: error => ({
    type: SET_ERROR_MESSAGE,
    payload: error,
  }),

  setCollection: collection => ({
    type: SET_COLLECTION,
    payload: collection,
  }),

  setCollections: collections => ({
    type: SET_COLLECTIONS,
    payload: collections,
  }),

  updateCollection: updatedCollection => ({
    type: UPDATE_COLLECTION,
    payload: updatedCollection,
  }),

  updateCollectionItem: updatedCollection => ({
    type: UPDATE_COLLECTION_ITEM,
    payload: updatedCollection,
  }),
};

// Reducer

export const myBritannicaReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COLLECTION: {
      const { collections } = state;
      const newCollection = action.payload;
      const updatedCollections = orderBy(
        [newCollection, ...collections],
        updatedCollectionItem => [updatedCollectionItem.isAllFavoritesCollection, updatedCollectionItem.createdDate],
        'desc'
      );

      return {
        ...state,
        collections: updatedCollections,
      };
    }

    case ADD_COLLECTION_ITEM: {
      const { collections, collection } = state;
      const updatedCollection = action.payload;
      const updatedCollections = collections.filter(item => item.collectionId !== updatedCollection.collectionId);
      const updatedCollectionsAndOrdered = orderBy(
        [...updatedCollections, updatedCollection],
        updatedCollectionItem => [updatedCollectionItem.isAllFavoritesCollection, updatedCollectionItem.createdDate],
        'desc'
      );

      if (collection && collection.collectionId === updatedCollection.collectionId) {
        return {
          ...state,
          collection: updatedCollection,
          collections: updatedCollectionsAndOrdered,
        };
      }

      return {
        ...state,
        collections: updatedCollectionsAndOrdered,
      };
    }

    case DELETE_COLLECTION: {
      const { collections } = state;
      const deletedCollectionId = action.payload;
      const updatedCollections = collections.filter(
        prevCollection => prevCollection.collectionId !== deletedCollectionId
      );

      return {
        ...state,
        collections: updatedCollections,
      };
    }

    case DELETE_COLLECTION_ITEM: {
      let updatedCollectionsAndOrdered = null;
      const { collections, collection } = state;
      const { updatedCollection, favoriteId } = action.payload;

      if (updatedCollection.isAllFavoritesCollection) {
        const updatedCollections = collections.map(prevCollection => {
          const updatedContents = prevCollection.contents.filter(content => content.favoriteId !== favoriteId);
          const updatedPrevCollection = { ...prevCollection };

          updatedPrevCollection.contents = updatedContents;

          return updatedPrevCollection;
        });

        updatedCollectionsAndOrdered = updatedCollections;
      } else {
        const updatedCollections = collections.filter(
          prevCollection => prevCollection.collectionId !== updatedCollection.collectionId
        );

        updatedCollectionsAndOrdered = orderBy(
          [...updatedCollections, updatedCollection],
          updatedCollectionItem => [updatedCollectionItem.isAllFavoritesCollection, updatedCollectionItem.createdDate],
          'desc'
        );
      }

      if (collection) {
        if (collection.collectionId === updatedCollection.collectionId) {
          return {
            ...state,
            collection: updatedCollection,
            collections: updatedCollectionsAndOrdered,
          };
        }

        const currentCollection = { ...collection };

        currentCollection.contents = collection.contents.filter(content => content.favoriteId !== favoriteId);

        return {
          ...state,
          collection: currentCollection,
          collections: updatedCollectionsAndOrdered,
        };
      }

      return {
        ...state,
        collections: updatedCollectionsAndOrdered,
      };
    }

    case SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }

    case SET_COLLECTION: {
      return {
        ...state,
        collection: action.payload,
      };
    }

    case SET_COLLECTIONS: {
      return {
        ...state,
        collections: action.payload,
      };
    }

    case UPDATE_COLLECTION: {
      const { collections } = state;
      const updatedCollection = action.payload;
      const updatedCollections = collections.filter(
        prevCollection => prevCollection.collectionId !== updatedCollection.collectionId
      );
      const updatedCollectionsAndOrdered = orderBy(
        [...updatedCollections, updatedCollection],
        updatedCollectionItem => [updatedCollectionItem.isAllFavoritesCollection, updatedCollectionItem.createdDate],
        'desc'
      );

      return {
        ...state,
        collection: updatedCollection,
        collections: updatedCollectionsAndOrdered,
      };
    }

    case UPDATE_COLLECTION_ITEM: {
      const updatedCollection = action.payload;

      return {
        ...state,
        collection: updatedCollection,
      };
    }

    default:
      return state;
  }
};
