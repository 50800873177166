import React, { useCallback, useEffect } from 'react';
import { Router } from 'react-router-dom';
import history from './history';
import AppRoutes from './App.routes';
import AnalyticsApi from './services/AnalyticsApi';
import myBritannicaApi from './services/MyBritannicaApi';
import privateContentApi from './services/PrivateContentApi';
import RouteChange from './components/RouteChange/RouteChange';
import { authActions } from './pages/Auth/Auth.dux';
import { myBritannicaActions } from './pages/MyBritannicaPage/MyBritannica.dux';
import useWithStore from './hooks/useWithStore.hook';
import AuthUtils from './utils/auth-utils';
import './App.scss';

const App = () => {
  const {
    store: { auth, collections },
    dispatch,
  } = useWithStore(state => ({ auth: state.auth, collections: state.myBritannica.collections }), {
    authSuccess: authActions.authSuccess,
    authFail: authActions.authFail,
    authInitial: authActions.authInitial,
    setCollections: myBritannicaActions.setCollections,
  });
  const { auth: authSub, error: authError } = auth;

  const getUserAttributes = useCallback(async () => {
    try {
      const userAttributes = await privateContentApi.getUserAttributes();

      dispatch.authSuccess(userAttributes);
    } catch (error) {
      dispatch.authFail(error);
    }
  }, [dispatch]);

  // if the access token is available, try fetching for the user attributes
  useEffect(() => {
    const { accessToken } = AuthUtils.getCognitoTokens();

    if (accessToken) {
      if (!authSub && !authError) {
        getUserAttributes();
      }
    } else {
      // EMF-700, EMF-669
      // If there is no accessToken present in cookies dispatch the authInitial action
      // with the payload - { responseReturned: true } - setting the Redux state back to its
      // initial state with the only exception being that responseReturned is set to true.
      // We need to do this so that the applicable condition within the JSX in Header.js
      // is triggered.
      dispatch.authInitial({ responseReturned: true });
    }
  }, [authError, authSub, dispatch, getUserAttributes]);

  // if the user is authenticated and collections is null, try to fetch for the collections.
  useEffect(() => {
    async function getCollections() {
      try {
        const collectionsResponse = await myBritannicaApi.getCollections();

        dispatch.setCollections(collectionsResponse);
      } catch (error) {
        dispatch.setCollections(error);
      }
    }

    if (auth.isAuthenticated && !collections) {
      getCollections();
    }
  }, [auth, collections, dispatch]);

  return (
    <Router history={history}>
      <RouteChange onRouteChange={AnalyticsApi.writeStat}>
        <AppRoutes />
      </RouteChange>
    </Router>
  );
};

export default App;
