import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ListGroupItem, Media } from 'reactstrap';

const SearchBoxMobileResultItem = ({ item, routePath, isInvertedTitle }) => (
  <ListGroupItem className="SearchBoxMobileResultItem ResultsList--item">
    <Media className="row">
      <Media body className="col-12">
        <Media className="result-item-title" left top tag={Link} to={`${routePath}${item.urlTitle}/${item.articleId}`}>
          <Media>{isInvertedTitle ? item.invertedTitle : item.title}</Media>
        </Media>
      </Media>
    </Media>
  </ListGroupItem>
);

SearchBoxMobileResultItem.propTypes = {
  item: PropTypes.shape().isRequired,
  routePath: PropTypes.string.isRequired,
  isInvertedTitle: PropTypes.bool,
};

SearchBoxMobileResultItem.defaultProps = {
  isInvertedTitle: true,
};

export default SearchBoxMobileResultItem;
