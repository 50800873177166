import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { Button, Form, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import './SearchBox.scss';

const SearchBox = props => {
  // Autosuggest
  const {
    getSuggestionValue,
    inputProps,
    suggestions,
    onSuggestionsClearRequested,
    onSuggestionsFetchRequested,
    onSuggestionSelected,
    renderSuggestion,
  } = props;

  // SearchBox
  const {
    className,
    dropdownOptions,
    hideSubmitButton,
    isSubmitDisabled,
    onSearchTypeChange,
    onSearchSubmit,
    placeholder,
    size,
    searchButtonColor,
    searchOptionValue,
  } = props;

  // Autosuggest theme
  const theme = {
    container: 'SearchBox--input autocomplete',
    containerOpen: 'open',
    input: 'autocomplete-input',
    inputOpen: 'open',
    suggestionsContainer: `autocomplete-suggestions-container ${size}`,
    suggestionsContainerOpen: 'open',
    suggestionsList: 'autocomplete-suggestions-list list-unstyled my-2',
    suggestion: `autocomplete-suggestion ${size}`,
    suggestionHighlighted: 'highlighted',
  };

  return (
    <Form className={`SearchBox ${className} ${dropdownOptions ? 'has-dropdown' : ''}`} onSubmit={onSearchSubmit}>
      <InputGroup size={size}>
        {suggestions ? (
          <Autosuggest
            theme={theme}
            inputProps={inputProps}
            suggestions={suggestions}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            renderInputComponent={inputComponentProps => (
              <Input
                {...inputComponentProps}
                aria-label={placeholder}
                placeholder={placeholder}
                bsSize={size}
                type="search"
              />
            )}
          />
        ) : (
          <Input
            aria-label={placeholder}
            placeholder={placeholder}
            className="SearchBox--input"
            {...inputProps}
            type="search"
          />
        )}
        {dropdownOptions && (
          <InputGroupAddon addonType="append" className="SearchBox--dropdown">
            <Input
              aria-label="selecione uma opção"
              value={searchOptionValue}
              type="select"
              bsSize={size}
              onChange={onSearchTypeChange}
              className={`custom-select ${size ? `custom-select-${size}` : ''}`}
            >
              {dropdownOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.displayValue}
                </option>
              ))}
            </Input>
          </InputGroupAddon>
        )}
        <InputGroupAddon addonType="append" className="SearchBox--submit">
          {/* setting 'searchButtonColor' on className and color prop deliberately to get focus effect out of the box. */}
          {!hideSubmitButton && (
            <Button
              aria-label={placeholder}
              className={`SearchBox--button bg-${!isSubmitDisabled && searchButtonColor}`}
              color={searchButtonColor}
              type="submit"
              disabled={isSubmitDisabled}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          )}
        </InputGroupAddon>
      </InputGroup>
    </Form>
  );
};

SearchBox.propTypes = {
  // Autosuggest
  getSuggestionValue: PropTypes.func,
  inputProps: PropTypes.shape(),
  onSuggestionsClearRequested: PropTypes.func,
  onSuggestionsFetchRequested: PropTypes.func,
  onSuggestionSelected: PropTypes.func,
  renderSuggestion: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.object),

  // SearchBox
  className: PropTypes.string,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      displayValue: PropTypes.string,
    })
  ),
  hideSubmitButton: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  onSearchTypeChange: PropTypes.func,
  onSearchSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  searchButtonColor: PropTypes.string,
  searchOptionValue: PropTypes.string,
};

SearchBox.defaultProps = {
  // Autosuggest
  getSuggestionValue: null,
  inputProps: null,
  onSuggestionsClearRequested: null,
  onSuggestionsFetchRequested: null,
  onSuggestionSelected: null,
  renderSuggestion: null,
  suggestions: null,

  // SearchBox
  className: '',
  dropdownOptions: null,
  hideSubmitButton: false,
  isSubmitDisabled: false,
  onSearchTypeChange: null,
  onSearchSubmit: null,
  placeholder: '',
  size: 'sm',
  searchButtonColor: 'quarternary',
  searchOptionValue: '',
};

export default SearchBox;
