import React from 'react';
import SearchWidgetTemplate from '../templates/SearchWidgetTemplate';
import SearchBoxContainer from '../../components/SearchBox/SearchBoxContainer';
import './SearchWidget.scss';
import escolaHdrBranding from '../../assets/images/escola_hdr_branding.png';

const SearchWidgetContainer = () => (
  <SearchWidgetTemplate>
    <div className="SearchWidgetWrapper">
      <img className="logo mb-2" src={escolaHdrBranding} alt="Britannica logo" />
      <SearchBoxContainer openResultsInNewTab />
    </div>
  </SearchWidgetTemplate>
);

export default SearchWidgetContainer;
