import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarToggler,
  Collapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { AuthType, AWSCognito, CompiledPath, RoutePath, Routes } from '../../constants';
import { Auth } from '../../pages/Auth/Auth.constants';
import { MyBritannica } from '../../pages/MyBritannicaPage/MyBritannica.constants';
import SearchBoxMobileContainer from '../SearchBoxMobile/SearchBoxMobileContainer';
import SearchBoxContainer from '../SearchBox/SearchBoxContainer';

import './Header.scss';
import escolaHdrBranding from '../../assets/images/escola_hdr_branding.png';
import escolaHdrBrandingCapes from '../../assets/images/eb_prtgs_escola2_hdr_branding_capes-mde-bgf.png';

const Header = props => {
  const {
    allFavoritesCollection,
    auth,
    isHelpNavLinkActive,
    isOpen,
    isSearchVisible,
    location,
    onAuthSignout,
    showSignoutIFrame,
    toggle,
  } = props;

  return (
    <Navbar className="Header" color="primary" dark expand="lg" fixed="top">
      {showSignoutIFrame && (
        <iframe
          style={{ display: 'none' }}
          title="signOut"
          src={`${AWSCognito.AUTH_HOST}/logout?client_id=${AWSCognito.CLIENT_ID}&logout_uri=${window.location.origin}`}
        />
      )}
      <Container>
        <div className="left-navbar-container">
          <NavbarBrand tag={Link} to={RoutePath.HOME}>
            <img className="logo" src={escolaHdrBranding} alt="Britannica logo" />
            <img className="logo sponsor" src={escolaHdrBrandingCapes} alt="Britannica Sponsor branding logo" />
          </NavbarBrand>
        </div>

        <div className="right-navbar-container d-lg-none text-right">
          {isSearchVisible && <SearchBoxMobileContainer />}
          <NavbarToggler onClick={toggle} aria-label="Ativar menu de navegação" />
        </div>

        <Collapse isOpen={isOpen} navbar>
          <Nav navbar className="ml-auto">
            {isSearchVisible && (
              <NavItem className="d-none d-lg-block">
                <SearchBoxContainer hasAutocomplete className="header-search" size="sm" />
              </NavItem>
            )}
            <NavItem>
              <NavLink
                tag={RouterNavLink}
                to={RoutePath.BROWSE_HELP.replace(':theme/:subject?/:topic?', '1/1000/1001')}
                isActive={isHelpNavLinkActive}
              >
                Ajuda
              </NavLink>
            </NavItem>
            {auth?.responseReturned && auth?.isAuthenticated && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="header-profile-dropdown-name">
                  {auth.firstName}
                </DropdownToggle>
                <DropdownMenu right>
                  {allFavoritesCollection && (
                    <DropdownItem>
                      <Link to={Routes.MY_BRITANNICA.toLink({ collectionId: allFavoritesCollection.collectionId })}>
                        {MyBritannica.TITLE}
                      </Link>
                    </DropdownItem>
                  )}
                  <DropdownItem divider />
                  <DropdownItem onClick={onAuthSignout}>{Auth.SIGNOUT}</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            {auth?.responseReturned && !auth?.isAuthenticated && (
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to={{
                    pathname: CompiledPath.AUTH({ authType: AuthType.AUTH_SIGNIN }),
                    state: { from: location.pathname },
                  }}
                >
                  {Auth.SIGNIN}
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Header.propTypes = {
  allFavoritesCollection: PropTypes.shape(),
  auth: PropTypes.shape({
    error: PropTypes.shape(),
    firstName: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    lastName: PropTypes.string,
    sub: PropTypes.string,
    username: PropTypes.string,
    responseReturned: PropTypes.bool,
  }),
  isHelpNavLinkActive: PropTypes.func,
  isSearchVisible: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  location: PropTypes.shape().isRequired,
  toggle: PropTypes.func.isRequired,
  onAuthSignout: PropTypes.func,
  showSignoutIFrame: PropTypes.bool,
};

Header.defaultProps = {
  allFavoritesCollection: null,
  auth: null,
  isHelpNavLinkActive: null,
  isSearchVisible: false,
  onAuthSignout: null,
  showSignoutIFrame: false,
};

export default Header;
