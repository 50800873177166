import { createContext } from 'react';

// Import reducers into store.
import { authReducer } from '../pages/Auth/Auth.dux';
import { myBritannicaReducer } from '../pages/MyBritannicaPage/MyBritannica.dux';

// Combine all reducers
export const combineReducers = (state = {}, action = {}) => {
  /**
   * We might need to do a shallow scan on the "state" object by doing a reference equality check on its properties
   * to figure out if we just want to return the current state or generate a new one.
   * This is only possible if the reducers we're building are updating their states immutability.
   *
   * Doing this will help reduce the amount of unnecessary re-renders in the "StoreProvider".
   */

  return {
    auth: authReducer(state.auth, action),
    myBritannica: myBritannicaReducer(state.myBritannica, action),
  };
};

// Create global store context and set initialState.
export const initialState = combineReducers();
export const store = createContext([initialState, () => {}]);
