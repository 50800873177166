import { compile } from 'path-to-regexp';
import { generateRoutes } from './utils/routing-utils';
import css from './variables.scss';

import artIcon from './assets/icons/art_icon.svg';
import literatureIcon from './assets/icons/literature_icon.svg';
import naturalScienceIcon from './assets/icons/natural_sciences_icon.svg';
import historyIcon from './assets/icons/history_icon.svg';
import sportsIcon from './assets/icons/sports_icon.svg';
import geographyIcon from './assets/icons/geography_icon.svg';
import mathIcon from './assets/icons/math_icon.svg';
import religionIcon from './assets/icons/religion_icon.svg';
// Games Browse Icons
import languageIcon from './assets/icons/language_icon.svg';
import mathematicsIcon from './assets/icons/mathematics_icon.svg';
import scienceIcon from './assets/icons/science_icon.svg';
import socialStudiesIcon from './assets/icons/social_studies_icon.svg';

export const RoutePath = {
  ARTICLE: '/artigo/:title/:articleId',
  AUTH: '/auth/:authType',
  BROWSE_ALPHA: '/seção/alfa/:alphaType/:alphaLetter/:pageNumber',
  BROWSE_ANIMALS: '/seção/animais/:theme/:subject/:topic?',
  BROWSE_ATLAS: '/seção/atlas/:isoCode?',
  BROWSE_HELP: '/seção/ajuda/:theme/:subject?/:topic?',
  BROWSE_GAMES: '/seção/jogos/:theme/:subject',
  BROWSE_THEME: '/seção/tema/:theme/:subject?/:topic*',
  BROWSE_VIDEOS: '/seção/vídeos/:theme',
  SEARCH_WIDGET: '/widgets/searchbox',

  GAMES_LINK: '',

  HOME: '/',
  HELP: '/ajuda',

  MEDIA_OVERLAY: '/recursos/:assemblyId',
  SEARCH: '/pesquisa/:searchType/:searchQuery',

  TEST: '/test',
  SAMPLE_PAGE: '/sample',
};

// Start using generateRoutes helper for "MY_BRITANNICA". We will eventually convert all our routes using this helper.
export const Routes = generateRoutes({
  MY_BRITANNICA: '/minhabritannica/:collectionId?',
  SHARED_COLLECTION: '/coleçãocompartilhada/:collectionId',
});

export const CompiledPath = {
  ARTICLE: compile(RoutePath.ARTICLE),
  AUTH: compile(RoutePath.AUTH),
  BROWSE_ALPHA: compile(RoutePath.BROWSE_ALPHA),
  BROWSE_ANIMALS: compile(RoutePath.BROWSE_ANIMALS),
  BROWSE_ATLAS: compile(RoutePath.BROWSE_ATLAS),
  BROWSE_HELP: compile(RoutePath.BROWSE_HELP),
  BROWSE_GAMES: compile(RoutePath.BROWSE_GAMES),
  BROWSE_THEME: compile(RoutePath.BROWSE_THEME),
  BROWSE_VIDEOS: compile(RoutePath.BROWSE_VIDEOS),
  SEARCH_WIDGET: compile(RoutePath.SEARCH_WIDGET),

  MEDIA_OVERLAY: compile(RoutePath.MEDIA_OVERLAY),
  SEARCH: compile(RoutePath.SEARCH),
};

// SearchType comes from react-router `match.params` prop, so needs to be translated

export const SearchType = {
  ARTICLES: 'artigos',
  DICTIONARY: 'dicionário',
  IMAGES: 'imagens',
  VIDEOS: 'vídeos',
};

export const AnimalkingdomType = {
  BYGROUPID: '335990',
  BYHABITATID: '335991',
};

export const Endpoint = {
  BACKEND_API: process.env.REACT_APP_BACKEND_API,
  CDN_URL: process.env.REACT_APP_CDN_URL,
  OPENSEARCH_API: process.env.REACT_APP_OPENSEARCH_API,
  GOOGLE_CLASSROOM: process.env.REACT_APP_GOOGLE_CLASSROOM_URL,
};

export const APIKeys = {
  GOOGLE_PLACES: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  RECAPTCHA_SITEKEY: process.env.REACT_APP_RECAPTCHA_SITEKEY,
};

export const WebStats = {
  ACCOUNT_ID: process.env.REACT_APP_ACCOUNT_ID,
  HOSTNAME_OVERRIDE: process.env.REACT_APP_HOSTNAME_OVERRIDE,
  HOST: process.env.REACT_APP_OVERRIDE_DOMAIN,
};

export const ApplicationVars = {
  ENV: process.env.REACT_APP_ENV,
  PAGE_SIZE: 20,
  DEBOUNCE_RATE: 200,
  RESULTS_LIST_THUMBNAIL_SIZE: '/s:300x200',
};

export const Breakpoints = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
};

export const GoogleDriveParameters = {
  CLIENT_ID: '320113273256-27sgcq2po275hmr7ltrehhlsfrhu0q4e.apps.googleusercontent.com',
  SCOPE: ['https://www.googleapis.com/auth/drive'],
  FOLDER_NAME: 'Britannica',
};

export const AWSCognito = {
  USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
  IDENTITY: `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
  AUTH_HOST: process.env.REACT_APP_COGNITO_AUTH_HOST,
};

export const MediaType = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  INTERACTIVE: 'interactive',
};

export const MediaQuery = {
  BETWEEN: '(min-width: :min) and (max-width: :max)',
  MAX: '(max-width: :max)',
  MIN: '(min-width: :min)',
};

export const ViewportWidth = {
  XS_MAX: css.screenXsMax,
  SM_MIN: css.screenSmMin,
  SM_MAX: css.screenSmMax,
  MD_MIN: css.screenMdMin,
  MD_MAX: css.screenMdMax,
  LG_MIN: css.screenLgMin,
  LG_MAX: css.screenLgMax,
  XL_MIN: css.screenXlMin,
  XL_MAX: `1549px`,
  XXL_MIN: `1550px`,
};

export const AnimalKingdomDefaults = [
  {
    browseId: 2650150,
    defaultBrowseId: 2677522,
  },
  {
    browseId: 2692307,
    defaultBrowseId: 2673509,
  },
  {
    browseId: 2650152,
    defaultBrowseId: 2654584,
  },
  {
    browseId: 2684935,
    defaultBrowseId: 2655429,
  },
  {
    browseId: 2650160,
    defaultBrowseId: 2655546,
  },
  {
    browseId: 2650161,
    defaultBrowseId: 2650161,
  },
  {
    browseId: 2692309,
    defaultBrowseId: 2692309,
  },
  {
    browseId: 2650162,
    defaultBrowseId: 2650162,
  },
  {
    browseId: 2684936,
    defaultBrowseId: 2684936,
  },
  {
    browseId: 2650163,
    defaultBrowseId: 2650163,
  },
  {
    browseId: 2650164,
    defaultBrowseId: 2650164,
  },
  {
    browseId: 2650166,
    defaultBrowseId: 2650166,
  },
  {
    browseId: 2650168,
    defaultBrowseId: 2650168,
  },
  {
    browseId: 2650171,
    defaultBrowseId: 2650171,
  },
  {
    browseId: 2650172,
    defaultBrowseId: 2650172,
  },
  {
    browseId: 2650173,
    defaultBrowseId: 2650173,
  },
];

export const Themes = [
  {
    browseId: 13001,
    title: 'Artes',
    url: '/seção/tema/13001',
    image: artIcon,
    color: 'art',
  },
  {
    browseId: 13043,
    title: 'Língua e literatura',
    url: '/seção/tema/13043',
    image: literatureIcon,
    color: 'literature',
  },
  {
    browseId: 13063,
    title: 'Ciências da Natureza',
    url: '/seção/tema/13063',
    image: naturalScienceIcon,
    color: 'natural-sciences',
  },
  {
    browseId: 13048,
    title: 'História',
    url: '/seção/tema/13048',
    image: historyIcon,
    color: 'history',
  },
  {
    browseId: 13087,
    title: 'Esportes',
    url: '/seção/tema/13087',
    image: sportsIcon,
    color: 'sports',
  },
  {
    browseId: 13011,
    title: 'Geografia',
    url: '/seção/tema/13011',
    image: geographyIcon,
    color: 'geography',
  },
  {
    browseId: 13047,
    title: 'Matemática',
    url: '/seção/tema/13047',
    image: mathIcon,
    color: 'mathematics',
  },
  {
    browseId: 13062,
    title: 'Religião',
    url: '/seção/tema/13062',
    image: religionIcon,
    color: 'religion',
  },
];

export const GameThemes = [
  {
    browseId: 2100,
    title: 'Ciências da Natureza',
    url: RoutePath.BROWSE_GAMES.replace(':theme/:subject', '2100/2110'),
    image: scienceIcon,
    color: 'natural-sciences',
    defaultSubjectId: 2110,
  },
  {
    browseId: 3100,
    title: 'Ciências Humanas',
    url: RoutePath.BROWSE_GAMES.replace(':theme/:subject', '3100/2130'),
    image: socialStudiesIcon,
    color: 'human-sciences',
    defaultSubjectId: 2130,
  },
  {
    browseId: 4100,
    title: 'Linguagens',
    url: RoutePath.BROWSE_GAMES.replace(':theme/:subject', '4100/4110'),
    image: languageIcon,
    color: 'literature',
    defaultSubjectId: 4110,
  },
  {
    browseId: 1400,
    title: 'Matemática',
    url: RoutePath.BROWSE_GAMES.replace(':theme/:subject', '1400/1510'),
    image: mathematicsIcon,
    color: 'mathematics',
    defaultSubjectId: 1510,
  },
];

export const VideoThemes = [
  {
    browseId: 14001,
    title: 'Artes',
    image: `${Endpoint.CDN_URL}/s:178.5x178.5/37/202137-107-DD1C93CC.jpg`,
    color: 'art',
    url: RoutePath.BROWSE_VIDEOS.replace(':theme', '14001'),
  },
  {
    browseId: 14002,
    title: 'Ciências da Natureza',
    image: `${Endpoint.CDN_URL}/s:178.5x178.5/59/199559-107-68B1A169.jpg`,
    color: 'natural-sciences',
    url: RoutePath.BROWSE_VIDEOS.replace(':theme', '14002'),
  },
  {
    browseId: 14003,
    title: 'Ciências Humanas',
    image: `${Endpoint.CDN_URL}/s:178.5x178.5/90/197490-107-6746FC5A.jpg`,
    color: 'human-sciences',
    url: RoutePath.BROWSE_VIDEOS.replace(':theme', '14003'),
  },
  {
    browseId: 14004,
    title: 'Esportes',
    image: `${Endpoint.CDN_URL}/s:178.5x178.5/56/140356-107-C0A177D7.jpg`,
    color: 'sports',
    url: RoutePath.BROWSE_VIDEOS.replace(':theme', '14004'),
  },
];

export const AuthType = {
  AUTH_FORGOT_PASSWORD: 'forgotpassword',
  AUTH_SIGNIN: 'signin',
  AUTH_SIGNUP: 'signup',
};

export const BrowseType = {
  AZ: 'az',
  ANIMALS: 'animais',
  BIOGRAPHIES: 'biografias',
  GAMES: 'jogos',
  THEMES: 'tema',
  VIDEOS: 'vídeos',
  SUBJECT: 'tema',
  HELP: 'ajuda',
};
