import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Button, Modal } from 'reactstrap';
import ResultsList from '../ResultsList/ResultsList';
import SearchBox from '../SearchBox/SearchBox';
import SearchBoxMobileResultItem from './SearchBoxMobileResultItem/SearchBoxMobileResultItem';
import './SearchBoxMobile.scss';

const SearchBoxMobile = ({ isModalOpen, onChange, onToggleModal, onSearchSubmit, searchResults, value }) => (
  <div className="SearchBoxMobile">
    <Button className="SearchBoxMobile--button" color="primary" onClick={onToggleModal} aria-label="search">
      <FontAwesomeIcon icon={faSearch} />
    </Button>
    <Modal isOpen={isModalOpen} toggle={onToggleModal} className="SearchBoxMobile--modal">
      <div className="searchbox-container">
        <SearchBox
          className="header-search"
          hideSubmitButton
          onSearchSubmit={onSearchSubmit}
          inputProps={{ value, onChange }}
          placeholder="Pesquisar"
          size="lg"
        />
        <Button className="modal-close-btn" onClick={onToggleModal} type="button" aria-label="close modal">
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
      {searchResults && (
        <ResultsList isInvertedTitle={false} list={searchResults} ResultsItemComp={SearchBoxMobileResultItem} />
      )}
    </Modal>
  </div>
);

SearchBoxMobile.propTypes = {
  isModalOpen: PropTypes.bool,
  onChange: PropTypes.func,
  onToggleModal: PropTypes.func,
  onSearchSubmit: PropTypes.func,
  searchResults: PropTypes.arrayOf(PropTypes.any),
  value: PropTypes.string,
};

SearchBoxMobile.defaultProps = {
  isModalOpen: false,
  onChange: null,
  onToggleModal: null,
  onSearchSubmit: null,
  searchResults: null,
  value: '',
};

export default SearchBoxMobile;
